<template>
    <v-row style="height: 92.5%">
        <v-col cols="2">
            <child-sidebar :children="children" />
        </v-col>
        <v-col>
            <router-view />
        </v-col>
    </v-row>
</template>

<script>
import ChildSidebar from '@/components/layout/ChildSidebar'

export default {
    name: 'SisImport',
    components: {
        ChildSidebar,
    },
    data: () => ({
        children: [
            {
                title: 'API Setup',
                route: 'sis-imports-api-setup',
            },
            {
                title: 'Templates',
                route: 'sis-imports',
            },
        ],
    }),
}
</script>
