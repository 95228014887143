<template>
    <v-list dense>
        <v-list-item v-for="(route, i) in children" :key="i" link :to="{ name: route.route }">
            <v-list-item-content>
                <v-list-item-title v-text="route.title"></v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>

<script>
export default {
    name: 'ChildSidebar',
    props: {
        children: {
            type: Array,
            required: true,
        },
    },
}
</script>

<style></style>
